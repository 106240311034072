<script setup lang="ts">
const props = defineProps({
  containerResultsClass: {
    type: String,
    default: '',
  },
  containerMultipleResultsClass: {
    type: String,
    default: '',
  },
});

const store = useTrackingStore();
const {isLookingNumber, isLoadingGuestTrackingSession, maxTrackingSessionsNumber, trackingSessions} = storeToRefs(store);

const registrationNumber = ref<string>('');
const isSearchDisabled = computed(() => registrationNumber.value.length <= 2 || isLookingNumber.value);

const { t } = useI18n();

const containerClass = computed<string>(() => {
  return (trackingSessions.value.length > 0 ? props.containerResultsClass : '')
    + ' '
    + (trackingSessions.value.length > 1 ? props.containerMultipleResultsClass : '');
});

const find = () => {
  const telegramWebApp = useTelegramWebApp();
  store.startTracking(registrationNumber.value)
      .then(session => {
        if (session) {
          registrationNumber.value = '';
        } else {
          telegramWebApp.showAlert(t('telegram.tracking.no_car_found'));
        }
      })
      .catch((error) => {
        telegramWebApp.showAlert(t('error_occurred_try_later'));
        captureException(error);
      });
};
</script>

<template>
  <div :class="containerClass">
    <div
      v-if="isLoadingGuestTrackingSession"
      class="d-flex w-75 m-auto"
    >
      <span
        class="spinner-border spinner-border-md text-success m-auto"
        aria-hidden="true"
      />
    </div>
    <div
      v-else-if="trackingSessions.length < maxTrackingSessionsNumber"
      class="d-flex w-75 m-auto"
    >
      <input
        v-model="registrationNumber"
        type="search"
        class="form-control me-2"
        maxlength="10"
        :placeholder="t('telegram.tracking.find_placeholder')"
        :disabled="isLookingNumber"
        @keyup.enter="find"
      >
      <button
        type="button"
        class="btn btn-outline-secondary d-inline-flex align-items-center"
        :disabled="isSearchDisabled"
        @click="find"
      >
        <span
          v-if="isLookingNumber"
          class="spinner-border spinner-border-sm text-secondary me-1"
          aria-hidden="true"
        />
        {{ t('telegram.tracking.find') }}
      </button>
    </div>
    <template v-if="trackingSessions.length > 0">
      <TelegramMiniTrackingSession
        v-for="session in trackingSessions"
        :key="session.id"
        :session="session"
      />
    </template>
  </div>
</template>
