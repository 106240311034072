<script setup lang="ts">
import QueueItem from "~/dto/queue/QueueItem";
import type TrackingSession from "~/dto/queue/TrackingSession";

const {t} = useI18n();

const props = defineProps({
  session: {
    type: Object as () => TrackingSession,
    required: true
  }
});

const store = useTrackingStore();

const isExpanded = ref<boolean>(false);

const item = computed<QueueItem>(() => props.session.item);

const toggle = () => {
  isExpanded.value = !isExpanded.value;
}

const close = () => {
  if (isExpanded.value) {
    toggle();

    return;
  }

  useTelegramWebApp().showConfirm(
    t('telegram.tracking.confirm_stop_tracking', {number: item.value.registrationNumber}),
    (isConfirmed: boolean) => {
      if (isConfirmed) {
        store.stopTracking(props.session);
      }
    }
  );
}
</script>

<template>
  <div
    :class="{'expanded': isExpanded}"
    class="tracking-session border rounded p-2 mt-2 position-relative bg-body"
  >
    <a
      class="icon-link position-absolute end-0 me-2"
      href="#"
      @click="close"
    >
      <button class="btn btn-close" />
    </a>
    <div
      v-if="isExpanded"
      class="row"
    >
      <div class="col-5 ps-3">
        <div
          v-if="item.isArrived"
          class="calling-order d-inline-block h1"
          @click="toggle"
        >
          <strong>{{ item.callingOrder }}</strong>
        </div>
        <div
          v-else
          class="h2 called"
          @click="toggle"
        >
          {{ t('telegram.tracking.queue_called') }}
        </div>
        <div>
          <div
            class="registration-number h3 d-inline-block border border-2 border-dark rounded px-1 m-0"
            @click="toggle"
          >
            <strong>{{ item.registrationNumber }}</strong>
          </div>
        </div>
      </div>
      <div class="col-7 text-nowrap">
        <div>
          <div
            v-tooltip="t('navbar.tracking.checkpoint')"
            class="h4 d-inline-block mt-1 mb-1"
          >
            <strong>{{ item.checkpoint!.name }}</strong>
          </div>
        </div>
        <div>
          <NavbarTrackingWaitingTime
            :item="item"
            class="d-flex align-items-center h5 mb-1"
          />
        </div>
        <div
          v-tooltip="t('navbar.tracking.arrived_time_title')"
          class="d-flex align-items-center h5 m-0"
        >
          <i class="cil-input icon icon-lg me-1" /> {{ item.registeredAt.toFormat('HH:mm, dd MMM') }}
        </div>
      </div>
    </div>
    <div
      v-else
      class="row"
    >
      <div class="col-12 ps-3">
        <div class="d-flex align-items-center">
          <div
            v-if="item.isArrived"
            class="calling-order d-inline-block"
            @click="toggle"
          >
            <strong>{{ item.callingOrder }}</strong>
          </div>
          <div
            v-else
            class="h2 called d-inline-block"
            @click="toggle"
          >
            {{ t('telegram.tracking.queue_called') }}
          </div>
          <div
            class="registration-number ms-auto d-inline-block border border-2 border-dark rounded px-1 m-0"
            @click="toggle"
          >
            <strong>{{ item.registrationNumber }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tracking-session {
  &:first-child {
    margin-top: 0 !important;
  }

  .btn-close:hover {
    background-color: #b39f9f;
  }

  .registration-number {
    font-size: 2.0rem;
    line-height: 2.0rem;
  }

  .calling-order {
    font-size: 2.2rem;
    line-height: 2.4rem;
  }

  .called {
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .row {
    .col-12 > div {
      padding-right: 2rem;
    }
  }

  &.expanded {
    padding-bottom: 0.75rem !important;

    .registration-number {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }

    .calling-order {
      margin-bottom: 0.2rem;
    }

    .called {
      margin-bottom: 0.2rem;
    }
  }
}
</style>